import React from "react";
import {
  TextField,
  MenuItem,
  Select,
  Checkbox,
  FormControl,
  InputLabel,
  FormGroup,
  FormControlLabel,
  Card,
  CardContent,
  FormHelperText,
} from "@mui/material";
import { FieldType, FormField } from "../models/models";

interface FormFieldBuilderProps {
  field: FormField;
  onChange: (id: string, value: any) => void;
}

const FormFieldBuilder: React.FC<FormFieldBuilderProps> = ({
  field,
  onChange,
}) => {
  const renderField = () => {
    console.log("Inside renderField");
    console.log(field);
    console.log((field.type as FieldType) === FieldType.Textfield);
    switch (field.type as FieldType) {
      case FieldType.Textfield:
        console.log("Inside Textfield");
        return (
          <TextField
            id={field.id}
            label={field.label}
            value={field.value as string | ""}
            onChange={(e) => onChange(field.id, e.target.value)}
            required={field.required}
            helperText={field.description}
            fullWidth
          />
        );
      case FieldType.Datefield:
        return (
          <TextField
            id={field.id}
            label={field.label}
            type="date"
            value={
              field.value
                ? (field.value as Date).toISOString().split("T")[0]
                : ""
            }
            onChange={(e) => onChange(field.id, new Date(e.target.value))}
            required={field.required}
            helperText={field.description}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />
        );
      case FieldType.SingleSelect:
        return (
          <FormControl fullWidth required={field.required}>
            <InputLabel id={`${field.id}-label`}>{field.label}</InputLabel>
            <Select
              labelId={`${field.id}-label`}
              id={field.id}
              value={field.value as string | ""}
              onChange={(e) => onChange(field.id, e.target.value)}
            >
              {field.options.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
            {field.description && (
              <FormHelperText>{field.description}</FormHelperText>
            )}
          </FormControl>
        );
      case FieldType.CheckList:
        return (
          <FormControl fullWidth required={field.required}>
            <label id={`${field.id}-label`}>
              {field.label}
              {field.required && (
                <div style={{ color: "red", display: "inline" }}>*</div>
              )}
            </label>
            <FormGroup>
              {field.options.map((option) => (
                <FormControlLabel
                  key={option}
                  control={
                    <Checkbox
                      checked={
                        field.value
                          ? (field.value as string[]).indexOf(option) > -1
                          : false
                      }
                      onChange={(e) => {
                        const selectedOptions = field.value as string[];
                        if (e.target.checked) {
                          selectedOptions.push(option);
                        } else {
                          const index = selectedOptions.indexOf(option);
                          if (index > -1) {
                            selectedOptions.splice(index, 1);
                          }
                        }
                        onChange(field.id, selectedOptions);
                      }}
                    />
                  }
                  label={option}
                />
              ))}
            </FormGroup>
            {field.description && (
              <FormHelperText>{field.description}</FormHelperText>
            )}
          </FormControl>
        );
      case FieldType.FileInput:
        return (
          <TextField
            id={field.id}
            label={field.label}
            type="file"
            onChange={(e) => {
              const target = e.target as HTMLInputElement;
              onChange(field.id, target.files ? target.files[0] : null);
            }}
            required={field.required}
            helperText={field.description}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />
        );
      default:
        return <div color="red">Unknown Type</div>;
    }
  };

  return (
    <Card>
      <CardContent>
        <p>Field# {field.id}</p>
        {renderField()}
      </CardContent>
    </Card>
  );
};

export default FormFieldBuilder;
