import React, { useState } from "react";
import {
  TextField,
  Button,
  Box,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  MenuItem,
  Snackbar,
  Alert,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Settings from "@mui/icons-material/Settings";
import { FormField } from "../models/models";
import OpenAI from "openai";

const systemP = `You are a helpful assistant designed to generate a list of form field JSON objects based on user prompts and context. These form fields will create a detailed procedure to guide a technician or engineer in performing their job with a step-by-step guide, including tools to check, safety measures to verify, and disposal steps. Use the context provided to get as much information about the situation and generate fields accordingly.

Your responses should directly generate the necessary steps, instructions, and documentation fields without asking the user for the steps. Each form field should be instructive or documentative, including:
- Initial checks (e.g., tool checklist, safety verifications)
- Detailed procedural steps (e.g., how to remove and replace components, measurements, and tests)
- Final verifications and documentation (e.g., confirming successful completion, documenting observations, disposal of parts, images etc.)

For example, if the user asks how to change a battery, generate the step-by-step procedure, including pre-checks, replacement steps, safety precautions, and final verification processes.`;

const AIForm = ({
  onFormChange,
}: {
  onFormChange: (fields: FormField[]) => void;
}) => {
  const [formPrompts, setFormPrompts] = useState<string>("");
  const [context, setContext] = useState<string>("");
  const [apiKey, setApiKey] = useState<string>(() => {
    const apiKeyFromCookie = document.cookie
      .split("; ")
      .find((row) => row.startsWith("apiKey="));
    return apiKeyFromCookie ? apiKeyFromCookie.split("=")[1] : "";
  });
  const [systemPrompt, setSystemPrompt] = useState<string>(systemP);
  const [model, setModel] = useState<string>("gpt-4o-mini");
  const [loading, setLoading] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<String>("");

  const handleModelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setModel(e.target.value);
  };

  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    if (!apiKey) {
      setSnackbarMessage("Please enter an API key.");
      setLoading(false);
      return;
    }

    if (!systemPrompt) {
      setSnackbarMessage("Please enter a system prompt.");
      setLoading(false);
      return;
    }

    if (!formPrompts) {
      setSnackbarMessage("Please enter form prompts.");
      setLoading(false);
      return;
    }

    try {
      const response = await generateFormFieldFromPrompt(
        apiKey,
        systemPrompt,
        model,
        formPrompts,
        context
      );
      if (response) {
        let output = JSON.parse(response);

        console.log("AI parsed output:", output);

        if (!output.fields || !Array.isArray(output.fields)) {
          setSnackbarMessage("Invalid response from OpenAI. Please try again.");
          setLoading(false);
          return;
        }

        // if (!output.fields.every(isValidFormField)) {
        //   setSnackbarMessage("Invalid form field structure. Please try again.");
        //   setLoading(false);
        //   return;
        // }

        onFormChange(output.fields);
      }
    } catch (error) {
      setSnackbarMessage("Failed to generate form fields. Please try again.");
      console.error("Error calling OpenAI:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleApiKeyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const apiKey = e.target.value;
    document.cookie = `apiKey=${apiKey}`;
    setApiKey(apiKey);
  };

  const handleSystemPromptChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSystemPrompt(e.target.value);
  };

  const handlePromptChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormPrompts(e.target.value);
  };

  const handleContextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setContext(e.target.value);
  };

  return (
    <Box
      component="form"
      onSubmit={handleFormSubmit}
      sx={{ display: "flex", flexDirection: "column", gap: 2 }}
    >
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3-content"
          id="panel3-header"
        >
          <Settings />
          <Box sx={{ width: 8 }}></Box>
          Settings
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
            <TextField
              id="openai-api-key"
              label="OpenAI API Key"
              rows={1}
              value={apiKey}
              type="password"
              onChange={handleApiKeyChange}
              variant="outlined"
              fullWidth
            />
            <TextField
              id="model"
              label="Model"
              select
              value={model}
              onChange={handleModelChange}
              variant="outlined"
              fullWidth
            >
              <MenuItem value="gpt-4o-mini">GPT 4o mini</MenuItem>
              <MenuItem value="gpt-4o">GPT 4o</MenuItem>
              <MenuItem value="gpt-4-turbo">GPT 4 turbo</MenuItem>
              <MenuItem value="gpt-4">GPT 4</MenuItem>
            </TextField>
            <TextField
              id="system-prompt"
              label="System prompt"
              multiline
              rows={6}
              value={systemPrompt}
              onChange={handleSystemPromptChange}
              variant="outlined"
              fullWidth
            />
          </Box>
        </AccordionDetails>
      </Accordion>
      <TextField
        id="formPrompts"
        label="Form Prompts"
        multiline
        rows={5}
        value={formPrompts}
        onChange={handlePromptChange}
        variant="outlined"
        fullWidth
      />
      <TextField
        id="context"
        label="Context"
        multiline
        rows={3}
        value={context}
        onChange={handleContextChange}
        variant="outlined"
        fullWidth
      />
      <Button type="submit" variant="contained" color="primary">
        {loading ? "Loading..." : "Generate Form Fields"}
      </Button>

      <Snackbar
        open={snackbarMessage.length > 0}
        autoHideDuration={4000}
        onClose={() => setSnackbarMessage("")}
      >
        <Alert severity="error" variant="filled" sx={{ width: "100%" }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AIForm;

async function generateFormFieldFromPrompt(
  apiKey: string,
  systemPrompt: string,
  model: string,
  prompt: string,
  context?: string
) {
  const openai = new OpenAI({
    apiKey: apiKey,
    dangerouslyAllowBrowser: true,
  });

  try {
    const response = await openai.chat.completions.create({
      model: model,
      messages: [
        {
          role: "system",
          content: systemPrompt,
        },
        {
          role: "user",
          content: context
            ? `Context: ${context}\n\nPrompt: ${prompt}`
            : prompt,
        },
      ],
      functions: [
        {
          name: "generate_form_field",
          description:
            "Generates a list of form field JSON objects based on the provided prompt.",
          parameters: {
            type: "object",
            properties: {
              fields: {
                type: "array",
                items: {
                  type: "object",
                  properties: {
                    id: { type: "string" },
                    label: { type: "string" },
                    description: { type: "string" },
                    type: {
                      type: "string",
                      enum: [
                        "textfield",
                        "datefield",
                        "singleselect",
                        "checklist",
                        "fileinput",
                      ],
                    },
                    options: {
                      type: "array",
                      items: { type: "string" },
                    },
                    required: { type: "boolean" },
                  },
                  required: ["id", "label", "type"],
                },
              },
            },
          },
        },
      ],
      function_call: { name: "generate_form_field" },
    });

    console.log("OpenAI response:", response);
    return response.choices[0].message.function_call?.arguments;
  } catch (error) {
    console.error("Error calling OpenAI:", error);
    throw error;
  }
}

// function isValidFormField(obj: any): obj is FormField {
//   return (
//     typeof obj === "object" &&
//     obj !== null &&
//     typeof obj.id === "string" &&
//     typeof obj.label === "string" &&
//     typeof obj.type === "string" &&
//     (typeof obj.value === "string" ||
//       typeof obj.value === "number" ||
//       obj.value === null)
//   );
// }

// You have been given a passage to generate questions from.
// There was a boy named Jack who lived with his mother. They were very poor. Jack's mother told him to sell their cow. On the way to the market

// You are a helpful assistant that generates a list of form field JSON objects based on user prompts and context that will be used to create a procedure to guide a technician/engineer to perform their job with a step-by-step guide and document their process.
