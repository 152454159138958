import React, { useState } from "react";
import { Tabs, Tab, Box } from "@mui/material";
import { FormField } from "./models/models";
import { AIForm, ManualAddForm } from "./components/components";

const FormBuilder: React.FC<{
  onFormChange: (newFormData: FormField[]) => void;
}> = ({ onFormChange }) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const handleFormChange = (newFormData: FormField[]) => {
    console.log("Form data:", newFormData);
    onFormChange(newFormData);
  };

  const handleAIFormChange = (response: any) => {
    console.log("AI Form data:", response);
    onFormChange(response);
  };

  return (
    <div style={{ flex: 2, padding: "16px" }}>
      <Tabs value={selectedTab} onChange={handleTabChange}>
        <Tab label="Manual" />
        {/* <Tab label="ReGex" /> */}
        <Tab label="AI" />
      </Tabs>
      <div style={{ padding: "16px" }} />
      <Box hidden={selectedTab !== 0}>
        <ManualAddForm onFormChange={handleFormChange} />
      </Box>
      {/* <Box hidden={selectedTab !== 1}>
        <p>Regex form goes here</p>
      </Box> */}
      <Box hidden={selectedTab !== 1}>
        <AIForm onFormChange={handleAIFormChange} />
      </Box>
      {/* Add more tab panels here if needed */}
    </div>
  );
};

export default FormBuilder;
