export enum FieldType {
    Textfield = 'textfield',
    Datefield = 'datefield',
    SingleSelect = 'singleselect',
    CheckList = 'checklist',
    FileInput = 'fileinput',
}

export interface FormField {
    id: string;
    label: string;
    description: string;
    type: FieldType;
    value: string | Date | string[] | File | null;
    options: string[];
    required: boolean;
}