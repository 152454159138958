import React, { useState, useEffect } from "react";
import { FormField, FieldType } from "../models/models";
import {
  TextField as MuiTextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

const ManualAddForm = ({
  onFormChange,
}: {
  onFormChange: (fields: FormField[]) => void;
}) => {
  const [fields, setFields] = useState<FormField[]>([]);
  const [selectedType, setSelectedType] = useState<FieldType>(
    FieldType.Textfield
  );
  const [label, setLabel] = useState("");
  const [required, setRequired] = useState(false);
  const [description, setDescription] = useState("");
  const [options, setOptions] = useState([""]);

  useEffect(() => {
    onFormChange(fields);
  }, [fields, onFormChange]);

  const handleAddField = () => {
    const newField: FormField = {
      id: `${fields.length + 1}`,
      label: label,
      description: description,
      type: selectedType as FieldType,
      value: selectedType === FieldType.CheckList ? [] : "",
      options: options.filter((o) => o),
      required: required,
    };
    setFields([...fields, newField]);
    setLabel("");
    setRequired(false);
    setDescription("");
    setOptions([""]);
  };

  const handleOptionChange = (value: string, index: number) => {
    const updatedOptions = options.map((option, i) =>
      i === index ? value : option
    );
    setOptions(updatedOptions);
  };

  const addOption = () => {
    setOptions([...options, ""]);
  };

  return (
    <div>
      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <FormControl variant="outlined" style={{ flex: 2 }}>
          <InputLabel id="field-type-label">Field Type</InputLabel>
          <Select
            labelId="field-type-label"
            label="Field Type"
            value={selectedType}
            onChange={(e) => setSelectedType(e.target.value as FieldType)}
            fullWidth
          >
            <MenuItem value={FieldType.Textfield}>Text</MenuItem>
            <MenuItem value={FieldType.Datefield}>Date</MenuItem>
            <MenuItem value={FieldType.SingleSelect}>Single Select</MenuItem>
            <MenuItem value={FieldType.CheckList}>Check List</MenuItem>
            <MenuItem value={FieldType.FileInput}>File</MenuItem>
          </Select>
        </FormControl>
        <MuiTextField
          label="Label"
          variant="outlined"
          value={label}
          onChange={(e) => setLabel(e.target.value)}
          style={{ flex: 3 }}
        />
      </div>
      <FormControl variant="outlined" style={{ marginTop: "10px" }} fullWidth>
        <InputLabel id="required-label">Required</InputLabel>
        <Select
          labelId="required-label"
          label="Required"
          value={required}
          onChange={(e) => setRequired(e.target.value === "true")}
          fullWidth
        >
          <MenuItem value="true">Yes</MenuItem>
          <MenuItem value="false">No</MenuItem>
        </Select>
      </FormControl>
      <MuiTextField
        label="Description"
        variant="outlined"
        value={description}
        multiline
        rows={4}
        onChange={(e) => setDescription(e.target.value)}
        margin="normal"
        fullWidth
      />
      {[FieldType.SingleSelect, FieldType.CheckList].includes(selectedType) &&
        options.map((option, index) => (
          <MuiTextField
            key={index}
            value={option}
            onChange={(e) => handleOptionChange(e.target.value, index)}
            label={`Option ${index + 1}`}
            variant="outlined"
            margin="normal"
            fullWidth
          />
        ))}
      {[FieldType.SingleSelect, FieldType.CheckList].includes(selectedType) && (
        <Button
          onClick={addOption}
          variant="contained"
          fullWidth
          style={{ marginTop: "10px" }}
        >
          Add Option
        </Button>
      )}

      <Button
        onClick={handleAddField}
        variant="contained"
        color="primary"
        style={{ marginTop: "20px" }}
        fullWidth
      >
        Add Field
      </Button>
    </div>
  );
};

export default ManualAddForm;
