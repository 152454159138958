import React from "react";
import { FormField } from "./models/models";
import { PreviewForm } from "./components/components";
import FormBuilder from "./FormBuilder";
import {
  AppBar,
  Divider,
  Typography,
  Box,
  Toolbar,
  useMediaQuery,
} from "@mui/material";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import { Theme } from "@mui/system";

const DynamicFormPage: React.FC = () => {
  const [formData, setFormData] = React.useState<FormField[]>([]);

  const handleFormChange = (newFormData: FormField[]) => {
    console.log("Form data:", newFormData);
    setFormData(newFormData);
  };

  // Use Material-UI's useMediaQuery to detect screen size
  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <SmartToyIcon sx={{ display: { xs: "flex", md: "flex" }, mr: 1 }} />
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            AI Form
          </Typography>
        </Toolbar>
      </AppBar>
      <div
        style={{
          display: "flex",
          flexDirection: isSmallScreen ? "column" : "row", // Change layout based on screen size
          padding: "16px",
          height: "100vh",
          overflow: "auto", // Prevent overflow issues on smaller screens
        }}
      >
        <div style={{ flex: 2, padding: "16px" }}>
          <FormBuilder onFormChange={handleFormChange} />
        </div>
        {!isSmallScreen && <Divider orientation="vertical" flexItem />}{" "}
        {/* Hide Divider on small screens */}
        <div style={{ flex: 3, padding: "16px" }}>
          <h2>Form Preview</h2>
          <div>
            <PreviewForm jsonOutput={JSON.stringify(formData)} />
          </div>
        </div>
      </div>
    </Box>
  );
};

export default DynamicFormPage;
