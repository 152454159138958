import React, { useState, useEffect } from "react";
import { FormField } from "../models/models";
import { FormFieldBuilder } from "./components";
import { Alert } from "@mui/material";

interface PreviewFormProps {
  jsonOutput: string;
}

const PreviewForm: React.FC<PreviewFormProps> = ({ jsonOutput }) => {
  const [formFields, setFormFields] = useState<FormField[]>([]);

  useEffect(() => {
    try {
      const parsedData = JSON.parse(jsonOutput);

      if (parsedData.length === 0) return;

      if (Array.isArray(parsedData) && parsedData.every(isValidFormField)) {
        setFormFields(parsedData as FormField[]);
      } else {
        console.error("Invalid JSON structure");
      }
    } catch (error) {
      console.error("Failed to parse JSON:", error);
    }
  }, [jsonOutput]);

  const handleFieldChange = (id: string, value: any) => {
    setFormFields((prevFields) =>
      prevFields.map((field) => (field.id === id ? { ...field, value } : field))
    );
  };

  function isValidFormField(obj: any): obj is FormField {
    return obj && typeof obj.id === "string" && typeof obj.label === "string";
  }

  if (formFields.length === 0) {
    return (
      <Alert variant="outlined" severity="info">
        No fields added. Either add them manually or write a prompt for AI to
        generate them.
      </Alert>
    );
  }

  return (
    <form>
      {formFields.map((field) => (
        <React.Fragment key={field.id}>
          <FormFieldBuilder field={field} onChange={handleFieldChange} />
          <div style={{ padding: "10px" }}></div>
        </React.Fragment>
      ))}
    </form>
  );
};

export default PreviewForm;
