import React from "react";
import DynamicFormPage from "./features/formBuilder/DynamicFormPage";
import { createTheme, ThemeProvider, CssBaseline } from "@mui/material";

// Create a theme instance.
const darkTheme = createTheme({
  palette: {
    mode: "dark", // Switches the theme to dark mode
  },
});

const App: React.FC = () => {
  return (
    // ThemeProvider makes the theme available down the React tree thanks to React context.
    <ThemeProvider theme={darkTheme}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      <DynamicFormPage />
    </ThemeProvider>
  );
};

export default App;
